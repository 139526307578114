import type { DiscussionPostResult } from '../data/fragments/DiscussionPostFragment'
import { createDiscussionTree, ExpandedPost } from '../../libs/discussion/createDiscussionTree'
import style from './Discussion.module.sass'
import { DiscussionPost } from './DiscussionPost'
import type { ReactNode } from 'react'
import clsx from 'clsx'
import * as React from 'react'
import { Container } from './Container'
import { useDiscussion } from '../auth/DiscussionContext'
import Script from 'next/script'

export const Discussion = React.memo(function Discussion(props: {
	posts?: DiscussionPostResult[]
	postsCount?: number
	heading?: string
	postsShowing?: number
	entering?: boolean
	enteringBtnText?: string
	textareaEl?: React.MutableRefObject<HTMLTextAreaElement | null>
	pinnedPostId?: string
}) {
	const discussionContext = useDiscussion()
	const posts =
		props.entering && discussionContext.pinnedDiscussionPost
			? [discussionContext.pinnedDiscussionPost]
			: props.posts
	const discussionTree = posts
		? createDiscussionTree(posts, props.pinnedPostId ? props.pinnedPostId : undefined)
		: null
	const postEls: Array<ReactNode> = []
	//const numOfPostsShowing = props.postsShowing ?? (posts ? posts.length : 1)
	let topLevelPosts = 0
	const defaultNumOfTopLevelPostsToBeShown = 4

	const [showAll, setShowAll] = React.useState<boolean>(false)

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	function renderPost(post: ExpandedPost) {
		return (
			<>
				<div
					className={clsx(
						style.item,
						post.isReply && style.isReply,
						topLevelPosts > defaultNumOfTopLevelPostsToBeShown && style.hide
					)}>
					<DiscussionPost
						textareaEl={props.textareaEl}
						key={post.post.id}
						post={post.post}
						enteringPost={props.entering}
					/>
				</div>
				{post.replies.map(
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore
					(post) => renderPost(post)
				)}
			</>
		)
	}

	function renderPosts(posts: typeof discussionTree) {
		if (posts) {
			posts.forEach((item) => {
				if (!item.isReply) {
					topLevelPosts = topLevelPosts + 1
				}
				const hasMoreThanOneReply =
					item.replies.length > 1 || (item.replies[0] && item.replies[0].replies[0])
				postEls.push(
					<div
						className={clsx(style.parentPost, hasMoreThanOneReply && style.parentPostHideReplies)}
						id={item.post.id}>
						{renderPost(item)}
						{/* {item.replies && (
							<div className="replies">{item.replies.map((item) => renderPost(item))}</div>
						)} */}
						{hasMoreThanOneReply && (
							<button
								onClick={() => {
									document.getElementById(item.post.id)?.classList.add(style.repliesVisible) //to be fixed
								}}
								className={clsx(
									style.showRepliesButton,
									topLevelPosts > defaultNumOfTopLevelPostsToBeShown && style.hide
								)}>
								Zobrazit další odpovědi
							</button>
						)}
					</div>
				)
			})
		}
	}

	renderPosts(discussionTree)

	return (
		<div className={clsx(style.wrapper, showAll && style.showall)}>
			<Script src="https://img2.cncenter.cz/js/default/standalone/dsaReportForm.js?v=1"></Script>
			<Container size="narrow">
				{props.entering && (
					<div className={clsx(style.head, !posts && style.noPosts)}>
						<h2 className={style.heading}>{props.heading ? props.heading : 'Řekni k tomu něco'}</h2>
						<a className={style.button} href={`${discussionContext.currentArticleUrl}/diskuze`}>
							{props.enteringBtnText ?? 'VSTOUPIT DO DISKUZE'}{' '}
							{!props.postsCount && posts && <span>{posts.length}</span>}
							{props.postsCount && <span>{props.postsCount}</span>}
						</a>
					</div>
				)}
				<div className={style.inner}>{postEls}</div>
				{!showAll && topLevelPosts > defaultNumOfTopLevelPostsToBeShown && (
					<button
						onClick={() => {
							setShowAll(true)
						}}
						className={style.showAll}>
						Zobrazit další komentáře ({topLevelPosts - defaultNumOfTopLevelPostsToBeShown})
					</button>
				)}
			</Container>
		</div>
	)
})
