import React from 'react'
import type { DiscussionPostResult } from '../data/fragments/DiscussionPostFragment'

export function useDiscussionContextRoot() {
	const [comment, setComment] = React.useState<{
		repliesTo?: string
		repliesToNick?: string
	} | null>()

	const [pinnedDiscussionPost, setPinnedDiscussionPost] =
		React.useState<null | DiscussionPostResult>()

	const [currentArticleUrl, setCurrentArticleUrl] = React.useState<null | string>()

	return {
		comment: comment,
		setComment: setComment,
		currentArticleUrl,
		setCurrentArticleUrl,
		pinnedDiscussionPost: pinnedDiscussionPost,
		setPinnedDiscussionPost: setPinnedDiscussionPost,
	}
}

export type DiscussionContext = ReturnType<typeof useDiscussionContextRoot>

export const DiscussionContext = React.createContext<null | DiscussionContext>(null)

export function DiscussionContextProvider(props: { children: React.ReactNode }) {
	const value = useDiscussionContextRoot()
	return <DiscussionContext.Provider value={value}>{props.children}</DiscussionContext.Provider>
}

export function useDiscussion() {
	const context = React.useContext(DiscussionContext)

	if (!context) {
		throw new Error('Missing DiscussionContext')
	}

	return context
}
