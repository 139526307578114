import React, { createContext, useContext } from 'react'

export type ImageSizesType = Record<string, string | undefined>

const ImageSizesContext = createContext<ImageSizesType>({})

export function ImageSizes({
	value,
	children,
}: {
	value: ImageSizesType
	children: React.ReactNode
}) {
	const current = useImageSizesContext()
	const serialized = JSON.stringify(value)
	const merged = React.useMemo(() => {
		const value = JSON.parse(serialized) as ImageSizesType
		return {
			...current,
			...value,
		}
	}, [current, serialized])
	return <ImageSizesContext.Provider value={merged}>{children}</ImageSizesContext.Provider>
}

export const useImageSizesContext = () => useContext(ImageSizesContext)

export const useImageSizes = (name: string) => useImageSizesContext()[name]
