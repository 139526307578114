import type { DiscussionPostResult } from '../../app/data/fragments/DiscussionPostFragment'

export type ExpandedPost = { post: DiscussionPostResult; isReply: boolean; replies: ExpandedPost[] }

export function createDiscussionTree(input: DiscussionPostResult[], pinnedPostId?: string) {
	const postsSortedByDate = input
		.sort(function (a, b) {
			const dateTime1 = new Date(String(a.createdAt)).getTime()
			const dateTime2 = new Date(String(b.createdAt)).getTime()
			return dateTime2 - dateTime1
		})
		.sort(function (a, b) {
			return a.id === pinnedPostId ? -1 : b.id === pinnedPostId ? 1 : 0
		})
	const replies: Record<string, DiscussionPostResult[] | undefined> = {}
	const repo: Record<string, DiscussionPostResult> = {}

	for (const item of postsSortedByDate) {
		const id = item.repliesTo?.id
		repo[item.id] = item

		if (id) {
			const parentReplies = (replies[id] = replies[id] ?? [])
			parentReplies.push(item)
		}
	}

	const rootPosts = postsSortedByDate.filter((post) => !post.repliesTo)

	const expandReplies = (post: DiscussionPostResult): ExpandedPost => ({
		post,
		isReply: Boolean(post.repliesTo),
		replies: replies[post.id]?.map((post) => expandReplies(post)) ?? [],
	})

	return rootPosts.map((post) => expandReplies(post))
}
