import moment from 'moment'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import csLocale from 'moment/locale/cs'
import * as React from 'react'
import { useDiscussion } from '../auth/DiscussionContext'
import { useAuth } from '../auth/UserContext'
import type { DiscussionPostResult } from '../data/fragments/DiscussionPostFragment'
import { useImageSizes } from '../utils/ImageSizesContext'
import { ContemberImage } from './ContemberImage'
import style from './DiscussionPost.module.sass'
import { Icon } from './Icon'
import { getBaseUrl } from '../utils/getBaseUrl'

export function DiscussionPost(props: {
	post: DiscussionPostResult
	enteringPost?: boolean
	textareaEl?: React.MutableRefObject<HTMLTextAreaElement | null>
}) {
	const sizes = useImageSizes('DiscussionPost') ?? '39px'
	const [likeCount, setLikeCount] = React.useState<number>(props.post.likedByReader.length)
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const createdAt = new Date(props.post.createdAt!)
	moment.locale('cs', [csLocale])
	const discussionContext = useDiscussion()
	const auth = useAuth()

	const [likes, setLikes] = React.useState<Array<string> | null>()
	React.useEffect(() => {
		if (auth.status === 'signedIn') {
			setLikes(auth.user.likedPosts.map((item) => item.id))
		}
	}, [auth])

	const commentDaktelaInfo = {
		threadId: props.post.discussion?.id,
		magazineId: 'HEYFOMO',
		commentId: props.post.id,
		commentAuthorId: props.post.author?.id,
		commentAuthorEmail: props.post.author?.email || '-',
		commentAnchorUrl:
			getBaseUrl() + `${props.post.discussion?.article?.url?.url}/diskuze#${props.post.id}`,
	}
	const jsonInfo = JSON.stringify(commentDaktelaInfo)
	const base64Info = btoa(unescape(encodeURIComponent(jsonInfo)))

	return (
		<div className={style.wrapper} id={props.post.id}>
			<div className={style.image}>
				{props.post.author?.avatar && (
					<ContemberImage
						image={{
							id: props.post.author.avatar.id,
							url: props.post.author.avatar.url,
							width: 40,
							height: 40,
						}}
						layout="fill"
						objectFit="cover"
						sizes={sizes}
					/>
				)}
			</div>
			<div className={style.content}>
				<article className={style.text} style={{ backgroundColor: props.post.author?.color }}>
					<b>{props.post.author?.nick} </b>
					{props.post.text}
				</article>

				<div className={style.bottom}>
					<div className={style.actions}>
						<time className={style.time}>{moment(createdAt).locale('cs').fromNow()}</time>
						<button
							type="button"
							className={style.respondButton}
							onClick={() => {
								if (auth.status === 'signedIn' && !props.enteringPost) {
									discussionContext.setComment((old) => {
										const update = { ...old }
										update['repliesTo'] = props.post.id
										update['repliesToNick'] = props.post.author?.nick
										return update
									})
									if (props.textareaEl) {
										props.textareaEl.current?.focus()
									}
								} else {
									if (auth.status === 'signedIn') {
										if (
											props.post.discussion &&
											props.post.discussion.article &&
											props.post.discussion.article.url
										) {
											window.location.replace(`${props.post.discussion?.article?.url?.url}/diskuze`)
										}
									} else {
										auth.initSignInIDP()
									}
								}
							}}>
							Odpovědět
						</button>{' '}
						<button data-dsa-report-form={base64Info} className={style.reportButton}>
							Nahlásit
						</button>
					</div>
					<button
						className={style.like}
						onClick={async () => {
							if (auth.status === 'signedIn') {
								const likePost = await auth.likePost({ postId: props.post.id })
								if (likePost.likedPosts) {
									setLikes(likePost.likedPosts.map((item) => item.id))
									setLikeCount(likeCount + 1)
								}
							} else {
								auth.initSignInIDP()
							}
						}}>
						{<Icon name={likes && likes.includes(props.post.id) ? 'heartFilled' : 'heart'} />}
						<span className={style.likeCount}>
							{auth.status === 'signedIn' && 'Like'} {likeCount > 0 && `(${likeCount})`}
						</span>
					</button>
				</div>
			</div>
		</div>
	)
}
